function hasOneDigit(val) {
    return String(Math.abs(val)).charAt(0) == val;
}
/**
 * Función para cambiar el formato de la fecha
 * 
 * @returns {number} La fecha con el formato cambiado a "años-mes-día"
 */
export const changeDateFormat = (dateWithoutFormat) => {
    if (dateWithoutFormat == "") return dateWithoutFormat;
    const date = new Date(dateWithoutFormat * 1000)
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (hasOneDigit(day) && hasOneDigit(month)) {
        return year + "-" + '0' + month + "-" + '0' + day;
    } else if (hasOneDigit(day)) {
        return year + "-" + month + "-" + '0' + day;
    } else if (hasOneDigit(month)) {
        return year + "-" + '0' + month + "-" + day;
    } else {
        return year + "-" + month + "-" + day;
    }
}
/**
 * Función para cambiar el formato de la fecha
 * 
 * @returns {number} La fecha con el formato cambiado a "día-mes-año"
 */

export const changeDateFormatTable = (dateWithoutFormat) => {
    if (dateWithoutFormat == "") return dateWithoutFormat;
    const date = new Date(dateWithoutFormat * 1000)
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (hasOneDigit(day) && hasOneDigit(month)) {
        return '0' + day + "/" + '0' + month + "/" + year
    } else if (hasOneDigit(day)) {
        return '0' + day + "/" + month + "/" + year
    } else if (hasOneDigit(month)) {
        return day + "/" + '0' + month + "/" + year;
    } else {
        return day + "/" + month + "/" + year;
    }
}

/**
 * Funcion para convertir una fecha aaaa-mm-dd en formato String a un formato Date
 * 
 * return {Date} La fecha en formato aaaa-mm-dd
 */
export const convertStringToDate = (date) => {
    let dateSplit = date.split("-");
    let year = dateSplit[0];
    let month = dateSplit[1];
    let day = dateSplit[2];
    return new Date(year, month - 1, day);
}

/**
* Función para transformar el timestamp en hora
*/
export const getTimeFormat = (timeStamp) =>{
    const date = new Date(timeStamp * 1000);
    const hour = String(date.getHours()).padStart(2, '0');
    const minutes = ('0' + date.getMinutes()).slice(-2);
    return `${hour}:${minutes}`;
}