<template>
  <CCard>
    <CCardBody>
      <div class="patients-limits">
        <CRow>
          <CCol sm="6">
            <CInput
              type="number"
              :label="minLabel"
              horizontal
              min="0"
              :value="min"
              disabled="true"
            />
          </CCol>
          <CCol sm="6">
            <CInput
              type="number"
              :label="maxLabel"
              horizontal
              min="0"
              :value="max"
              disabled="true"
            />
          </CCol>
        </CRow>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "LimitCard",
  props: {
    min: {
      type: Number,
      required: true,
    },
    minLabel: {
        type: String,
        default: 'Limite mínimo'
    },
    max: {
      type: Number,
      required: true,
    },
    maxLabel: {
        type: String,
        default: 'Limite máximo'
    }
  },
};
</script>

<style scoped>
.card{
    border: 0;
}
</style>